import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const BrandsIndexPage = makeShortcode("BrandsIndexPage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {/* Page title left out so it can be generated */}
    <PageDescription mdxType="PageDescription">
      <p>{`Ниже вы можете увидеть бренды.`}</p>
    </PageDescription>
    <BrandsIndexPage color="light" mdxType="BrandsIndexPage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      